/* Screening Questionnaire  */
.screening-questionnaire-section {
    .questionnaire-block {
        .checkbox-option,
        .radio-option {
            cursor: pointer;
            * {
                cursor: pointer;
            }
        }

        .checkbox-option {
            .p-component {
                position: absolute;
            }
            .p-checkbox-box {
                display: none;
            }
        }
    }
}

/* Appointment */
.appointement-block {
    &.scheduled {
        iframe {
            display: none;
        }
    }
}

/* Common Layout */
@media only screen and (max-width: 768px) {
    .main-component-block {
        .position-center {
            margin: 1.25rem 0rem;
        }
    }
}