body {
  margin: 0;
  font-family: 'Futur Luxe', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}
.ms-2 {
  margin-left: .5rem !important;
}
/* padding */
.pt-3 {
  padding-top: 1rem !important;
}

.text-start {
  text-align: left !important;
}

.d-flex{
  display: flex !important;
} 

.align-items-start{
  align-items: start !important;
}

.align-items-center{
  align-items: center !important;
}

.flex-column {
  flex-direction: column !important;
}

.gap-3 {
  gap: 1rem !important;
}