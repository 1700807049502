/* Waitlist Country Code */
.waitlist-form-container {

  .auto-complete-select__placeholder{
    position: absolute;
    left: 4px;
    font-size: 16px;

  }

  .auto-complete-select__input-container{
    text-align: start;
    
    input{
      text-align: start;

      // &::placeholder{
      //   text-align: left;
      // }
    }
  }

  .auto-complete-select__menu-list{
    // transform: translate(0px, 54px);
    margin-top: 54px;
    max-height: 210px;
    padding: 0px;
    border-radius: 0px !important;

    .auto-complete-select__option{
      width: 100%;
      padding: 0px;

      &>section {
        width: 100%;
        border-bottom: 1px solid #3D3D48;
        margin: 0 10px;      
        padding: 14px 0;
      }
    }
  }

  .auto-complete-select__control--is-focused {
    border-radius: 12px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .auto-complete-select__control--menu-is-open {
    border-radius: 0px !important;
    padding: 10px 20px
  }

  .auto-complete-select__single-value img {
    display: none !important;
  }

  .auto-complete-select__indicator,
  .auto-complete-select__indicator-separator {
    visibility: hidden;
  }

  .auto-complete-select {
    &.selected {
      .auto-complete-select__control {
        background: #23252D;
      }
    }
  }

}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    appearance: none;
    background: none !important;
    color: inherit !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
}
