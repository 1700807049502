.payment-flow-section {

    // .inner-block {
    //     padding: 60px;
    //     border-radius: 16px;
    //     background: #121317;
    // }

    .verification-block {
        input::selection {
            color: inherit;
            background: transparent;
        }
    }

    .communication-block {

        .checkbox-options {
            .checkbox-option {
                display: flex;
                justify-content: space-between;
                align-items: center;

                // 
                width: 100%;
                height: 69px;
                padding: 20px;
                gap: 24px;
                border-radius: 16px;
                border: 1px solid #35394A;
                cursor: pointer;

                * {
                    cursor: pointer;
                }

                .checkbox-label {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 28.8px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #D3D3DA;
                    width: -webkit-fill-available;
                }

                &.not-selected {
                    color: #0E0E11;

                    .p-checkbox-box {
                        border-radius: 50%;
                        background: transparent;
                        border-color: #A1A1AF;
                    }
                }

                .p-checkbox-box {
                    border-radius: 50%;
                    background: transparent;
                    border-color: #A1A1AF;

                    // background: #71F0FF;
                    // border-color: #71F0FF;

                    svg {
                        color: #0D0E11;
                    }
                }
            }
        }

        .phone-number {
            font-size: 24px;
            font-weight: 500;
            line-height: 28.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #F1F3F6;
        }

        .send-otp-button {
            width: fit-content;
            height: 64px;
            padding: 16px 40px 16px 40px;
            gap: 12px;
            border-radius: 16px;
            border: 1px solid #C5C5CE;
            background: #D3D3DA;
            margin-top: 1rem;

            font-size: 24px;
            font-weight: 600;
            line-height: 31.82px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #1C1C1C;
            text-transform: none;
        }
    }


}

.address-details-block {
    .auto-complete-select-label {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #D3D3DA;
        background-color: transparent;
    }

    .MuiAutocomplete-paper {
        background-color: #121317 !important;
        border: 1px solid white !important;
        border-top: none !important;
    }
    
    .auto-complete-select__menu>div {
        max-height: 220px;
    }
    
    .auto-complete-select__menu {
        // z-index: 9999999999 !important;
        // position: absolute;
    }
}

@media only screen and (max-width: 768px) {
    .payment-flow-section {
        // .inner-block {
        //     padding: 20px;
        //     margin-top: 1.5rem !important;
        // }

        .communication-block {
            .heading-text {
                font-size: 24px;
            }

            .checkbox-options {
                .checkbox-option {
                    height: 60px;
                    padding: 15px;

                    .checkbox-label {
                        font-size: 20px;
                        line-height: 24.4px;
                    }
                }
            }

        }

    }
}


.auto-complete-select__menu-list {
    .auto-complete-select__option {
        width: 100%;
        padding: 0px;

        &>section {
            width: 100%;
            border-bottom: 1px solid #3D3D48;
            margin: 0 10px;
            padding: 14px 0;
        }
    }
}

.auto-complete-select__menu {
    margin-top: 0px !important;
    border: 1px solid #AFAFBB;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    overflow: hidden;
}

.auto-complete-select__control--menu-is-open {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.auto-complete-select__single-value img {
    display: none !important;
}

.auto-complete-select__indicator,
.auto-complete-select__indicator-separator {
    visibility: hidden;
}

.auto-complete-select {
    &.selected {
        .auto-complete-select__control {
            background: #23252D;
        }
    }
}