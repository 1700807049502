/* Futur-Luxe */
@font-face {
  font-family: 'Futur Luxe';
  font-style: normal;
  font-weight: 200;
  src: local('Futur Luxe'), url(../fonts/Futur-Luxe-Extralight.ttf) format('truetype');
}
@font-face {
  font-family: 'Futur Luxe';
  font-style: normal;
  font-weight: 300;
  src: local('Futur Luxe'), url(../fonts/Futur-Luxe-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Futur Luxe';
  font-style: normal;
  font-weight: 400;
  src: local('Futur Luxe'), url(../fonts/FuturLuxe-Regular.ttf) format('truetype');
  /* src: local('Futur Luxe'), url(../fonts/Futur-Luxe-Regular.ttf) format('truetype'); */
}
@font-face {
  font-family: 'Futur Luxe';
  font-style: normal;
  font-weight: 500;
  src: local('Futur Luxe'), url(../fonts/FuturLuxe-Medium.ttf) format('truetype');
  /* src: local('Futur Luxe'), url(../fonts/Futur-Luxe-Medium.ttf) format('truetype'); */
}
@font-face {
  font-family: 'Futur Luxe';
  font-style: normal;
  font-weight: 600;
  src: local('Futur Luxe'), url(../fonts/FuturLuxe-Semi-Bold.ttf) format('truetype');
  /* src: local('Futur Luxe'), url(../fonts/Futur-Luxe-Semibold.ttf) format('truetype'); */
  
}
@font-face {
  font-family: 'Futur Luxe';
  font-style: normal;
  font-weight: 700;
  src: local('Futur Luxe'), url(../fonts/Futur-Luxe-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Futur Luxe';
  font-style: normal;
  font-weight: 800;
  src: local('Futur Luxe'), url(../fonts/Futur-Luxe-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Futur Luxe';
  font-style: normal;
  font-weight: 900;
  src: local('Futur Luxe'), url(../fonts/Futur-Luxe-Black.ttf) format('truetype');
}