* {
    box-sizing: border-box;
    font-family: 'Futur Luxe', Arial, sans-serif !important;
}

.hide{
    display: none !important;
}
.hidden{
    visibility: hidden !important;
}

body{
    /* background: linear-gradient(108.25deg, #000000 2.26%, #0F0F0F 96.41%); */
    background: #0F0F0F;
}