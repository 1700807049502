@import url('./assets/css/fonts.css');
@import url('./assets/css/base.scss');

.App {
  text-align: center;
}

.main-component-block {
  min-height: calc(100vh - 90px);
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.main-component-block .position-center {
  max-width: 621px;
  /* margin: 4rem 8rem; */
}

.main-component-outer-block {
  /* padding: 4rem 5rem; */
  padding: 4rem 0rem;
}

.MuiContainer-root {
  min-height: calc(100vh - 150px);
}

.preloader-lottie-animation svg {
  max-width: 100px;
}

.swal2-popup {
  background: #cdcdcd;
}

.swal2-popup .swal2-icon {
  border-color: #fb915a;
  color: #fb915a;
}

.swal2-popup .swal2-title {
  color: #000000;
}

.swal2-popup .swal2-confirm {
  background: #000000;
}

@media only screen and (max-width: 768px) {
  .main-component-outer-block {
    /* padding: 2rem 2rem; */
    padding: 2rem 0rem;
  }

  .swal2-popup {
    width: 85%;
  }

  .swal2-icon {
    width: 4em;
    height: 4em;
  }

  .swal2-title {
    font-size: 20px;
  }

  .preloader-lottie-animation svg {
    max-width: 100px;
  }

  .MuiContainer-root {
    min-height: calc(100vh - 150px);
  }
}

/*  */

.auto-complete-select__menu>div,
.MuiAutocomplete-listbox {
  scrollbar-width: thin;
  /* scrollbar-height: thin; */
  scrollbar-color: #121317 #A1A1AF;
}

.auto-complete-select__menu>div::-webkit-scrollbar,
.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.auto-complete-select__menu>div::-webkit-scrollbar-track,
.MuiAutocomplete-listbox::-webkit-scrollbar-track {
  background: #A1A1AF;
}

.auto-complete-select__menu>div::-webkit-scrollbar-thumb,
.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background-color: #121317;
  border-radius: 10px;
  border: 2px solid #A1A1AF;
}

.auto-complete-select__menu>div::-webkit-scrollbar-thumb:hover,
.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
  background-color: #121317;
}